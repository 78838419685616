<template>
  <v-app style="z-index: 1">
    <!--app-bar v-if="client"/-->
    <app-drawer v-if="client"/>
    <v-container class="pa-0" style="height: 100%;max-width: 100%;width: 100%; background-color: #f2f2f2">
      <v-main :class="{'pa-0': !client}" :style="client ? {} : {height: '100%'}">
        <router-view/>
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
import AppDrawer from './components/AppDrawer.vue'

import {mapState} from 'vuex'

export default {
  name: 'App',

  components: { AppDrawer },

  data: () => ({}),

  computed: {
    ...mapState(['client']),
  },

  created() {
  }
};
</script>

<style>
html {
  overflow-y: auto !important;
}

@media (min-width: 1904px) {
  .col-xl-fifth {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.v-application {
  font-family: 'SF Pro Display', sans-serif !important;
}

.br-16 {
  border-radius: 16px !important;
}

.br-32 {
  border-radius: 32px !important;
}

.v-btn {
  text-transform: none;
}

.table-aggregate-card {
  border-color: #80717080;
}

.table-aggregate {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-align: center;
  color: #29292980;
  white-space: nowrap;
  min-width: 300px;
}

.v-input--selection-controls {
  margin-top: 0 !important;
}

.v-messages {
  display: none;
}

</style>