export const fmtDate = (isoDt, options) => {
  if (!isoDt) {
    return ''
  }
  const d = new Date(isoDt)
  return d.toLocaleDateString('ru-RU', options)
}

export const fmtMoney = data => {
  return (data.value || 0).toLocaleString(undefined, { minimumFractionDigits: 2 }).replace(',', '.') + ' ' + data.symbol
}

export const fldComp = (field, desc) => {
  return (a, b) => {
    if (a[field] > b[field]) {
      return desc ? -1 : 1;
    }
    if (a[field] < b[field]) {
      return desc ? 1 : -1;
    }
    return 0;
  }
}

export const fieldsContains = (obj, fields, value) => {
  for (let f of fields) {
    if (obj[f] !== undefined && obj[f] !== null && obj[f].toLowerCase().indexOf(value.toLowerCase().trim()) > -1) {
      return true
    }
  }
  return false
}

export const dealsInRow = () => {
  const { innerWidth } = window
  return innerWidth > 1904 ? 4 :
      innerWidth > 1264 ? 3 :
          innerWidth > 960 ? 2 : 1
}

export const fixStatusName = val => val.replace('✅', '✓ ').replace('✖', '⨯ ').replace('⭐', '★ ')