import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru'
  },
  theme: {
    themes: {
      dark: {
        primary: '#459F9A',
        error: '#DB402B',
        alert: '#EAB03E',
        info: '#1E88B5',
      },
      light: {
        primary: '#459F9A',
        error: '#DB402B',
        alert: '#EAB03E',
        info: '#1E88B5',
      },
    },
  },
});
