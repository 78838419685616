import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/lk/',
    name: 'lk',
    component: () => import('../components/Lk'),
    children: [{
      path: '',
      name: 'home',
      component: () => import('../views/Home'),
    }, {
      path: 'deal/:id',
      name: 'deal',
      component: () => import('../views/Deal'),
    }, {
      path: 'invoices/',
      name: 'invoices',
      component: () => import('../views/Invoices'),
    }],
  },
  {
    path: '/login/',
    name: 'login',
    component: () => import('../views/Auth'),
  },
]


const router = new VueRouter({
  mode: 'history',
  // base: '', //window.location.pathname,
  routes,
})

export default router
