import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {fieldsContains, fldComp} from '@/helpers'

Vue.use(Vuex)

const vueDataEl = document.getElementById('vue-data')
const vueData = vueDataEl ? JSON.parse(vueDataEl.text) : {}

function saveDealSorting({ field, type }) {
  localStorage.setItem('lkDealSortingField', field)
  localStorage.setItem('lkDealSortingType', type)
}

function saveDealFilter({ filter }) {
  localStorage.setItem('lkDealFilter', filter)
}

function saveDealSettingsFlag({ flag, value }) {
  let key = 'lkDealFlag_' + flag
  if (value) {
    localStorage.setItem(key, '1')
  } else {
    localStorage.removeItem(key)
  }
}

function saveDealStageFilter({ filter }) {
  localStorage.setItem('lkDealStageFilter', filter)
}

function saveDealAttentionRequiredFilter({ on }) {
  if (on) {
    localStorage.setItem('lkDealAttentionRequiredFilter', '1')
  } else {
    localStorage.removeItem('lkDealAttentionRequiredFilter')
  }
}

function getDealSettings() {
  return {
    field: localStorage.getItem('lkDealSortingField') || 'spec_num',
    type: localStorage.getItem('lkDealSortingType') || 'desc',
    filter: localStorage.getItem('lkDealFilter') || '',
    attentionRequired: !!localStorage.getItem('lkDealAttentionRequiredFilter'),
    hideClosed: !!localStorage.getItem('lkDealFlag_hideClosed'),
    manufacturePayment: !!localStorage.getItem('lkDealFlag_manufacturePayment'),
    stageFilter: localStorage.getItem('lkDealStageFilter') ?
        localStorage.getItem('lkDealStageFilter').split(',') :
        '',
  }
}

const dealSettings = getDealSettings()

export default new Vuex.Store({
  state: {
    portalDomain: vueData.portalDomain,
    olWidgetUrl: vueData.olWidgetUrl,
    client: vueData.client,
    responsible: vueData.responsible,
    deals: null,
    dealDetails: {},
    dealStages: [],
    unreadMessages: [],
    lastMessages: {},
    news: null,
    selectedCells: [],
    dealSorting: {
      field: dealSettings.field,
      type: dealSettings.type,
    },
    defaultSortingTypes: {
      spec_num: 'desc',
      manufacture: 'asc',
    },
    dealFilter: dealSettings.filter,
    dealStageFilter: dealSettings.stageFilter,
    dealAttentionRequiredFilter: dealSettings.attentionRequired,
    dealHideClosed: dealSettings.hideClosed,
    dealManufacturePayment: dealSettings.manufacturePayment,
  },
  getters: {
    cellSum: state => {
      return state.selectedCells.reduce((sum, cell) => sum + cell.value, 0).toFixed(2);
    },
    cellAvg: (state, getters) => {
      return ((getters.cellSum / state.selectedCells.length) || 0).toFixed(2);
    },
    isCellSelected: state => ({ deal, field }) => {
      const index = state.selectedCells.findIndex(cell => cell.deal_id === deal.id && cell.field === field)
      return index > -1
    },
    sortedDeals: state => (
        state.dealSorting.field === 'spec_num' ? (
            state.dealSorting.type === 'desc' ? state.deals : state.deals.slice().reverse()
        ) : state.dealSorting.field === 'manufacture' ? (
            state.deals.slice().sort(fldComp('manufacture', state.dealSorting.type === 'desc'))
        ) : state.deals
    ).filter(
        d => state.dealFilter ?
            fieldsContains(d, ['spec_num', 'manufacture', 'stage', 'requisite'], state.dealFilter) :
            true
    ).filter(
        d => state.dealStageFilter.length ?
            state.dealStageFilter.indexOf(d.stage_id) > -1 :
            true
    ).filter(
        d => state.dealHideClosed ?
            !(d.stage_id === 'WON' || d.stage_id === 'LOSE') :
            true
    ).filter(
        d => state.dealManufacturePayment ?
            d.payment_to_manufacture :
            true
    ),
  },
  mutations: {
    setDeals(state, { deals }) {
      deals.forEach(d => {
        d.total_leftover = {
          value: Math.max((d.invoice_leftover.value || 0) + (d.service_leftover.value || 0), 0),
          symbol: d.invoice_leftover.symbol
        }
      })
      state.deals = deals
    },
    setDealDetail(state, { dealId, detail }) {
      Vue.set(state.dealDetails, dealId, detail)
    },
    setDealStages(state, { stages }) {
      state.dealStages = stages
    },
    setDealRequisites(state, { requisites }) {
      state.deals.forEach(d => {
        Vue.set(d, 'requisite', requisites[d.id])
        if (state.dealDetails[d.id]) {
          Vue.set(state.dealDetails[d.id], 'requisite', requisites[d.id])
        }
      })
    },
    setUnreadMessages(state, { unread }) {
      state.unreadMessages = unread
    },
    changeDealSorting(state, { field }) {
      const type = (
          state.dealSorting.field === field ? (
              state.dealSorting.type === 'desc' ? 'asc' : 'desc'
          ) : state.defaultSortingTypes[field]
      )
      state.dealSorting = { field, type }
      saveDealSorting({ field, type })
    },
    setDealFilter(state, { filter }) {
      filter = filter || ''
      state.dealFilter = filter
      saveDealFilter({ filter: filter })
    },
    setDealStageFilter(state, { filter }) {
      filter = filter || []
      state.dealStageFilter = filter
      saveDealStageFilter({ filter: filter })
    },
    setDealHideClosed(state, { value }) {
      state.dealHideClosed = value
      saveDealSettingsFlag({ flag: 'hideClosed', value })
    },
    setDealManufacturePayment(state, { value }) {
      state.dealManufacturePayment = value
      saveDealSettingsFlag({ flag: 'manufacturePayment', value })
    },
    setDealAttentionRequiredFilter(state, { on }) {
      state.dealAttentionRequiredFilter = !!on
      saveDealAttentionRequiredFilter({ on })
    },
    selectCell(state, { deal, field }) {
      if (!deal[field]) {
        return
      }
      
      const index = state.selectedCells.findIndex(cell => cell.deal_id === deal.id && cell.field === field)
      if (index > -1) {
        state.selectedCells.splice(index, 1)
      } else {
        state.selectedCells.push({
          deal_id: deal.id, field,
          value: deal[field].value,
        })
      }
    },
    setLastMessageText(state, { dealId, text }) {
      Vue.set(state.lastMessages, dealId, text)
    },
    setNews(state, { news }) {
      state.news = news
    },
  },
  actions: {
    async getDeals({ commit }) {
      const { data: { deals } } = await axios.get('/api/deals/')
      await commit('setDeals', { deals })
    },
    async getDealDetail({ commit }, { dealId }) {
      const { data: { deal } } = await axios.get('/api/deal_detail/', { params: { deal_id: dealId } })
      await commit('setDealDetail', { dealId, detail: deal })
    },
    async getDealStages({ commit }) {
      const { data: { stages } } = await axios.get('/api/deal_stages/')
      await commit('setDealStages', { stages })
    },
    async getDealRequisites({ state, commit }) {
      const { data: { requisites } } = await axios.get('/api/deal_requisites/?ids=' + state.deals.map(d => d.id).join(','))
      await commit('setDealRequisites', { requisites })
    },
    async getUnreadMessages({ commit }) {
      const { data: { unread } } = await axios.get('/api/unread_messages/')
      await commit('setUnreadMessages', { unread })
    },
    async getLastMessageText({ commit }, { dealId }) {
      const { data: { text } } = await axios.get(`/api/last_message/?deal_id=${dealId}`)
      await commit('setLastMessageText', { dealId, text })
    },
    async getNews({commit }) {
      const { data: { news } } = await axios.get('/api/get_news/')
      await commit('setNews', { news })
    },
  },
})
